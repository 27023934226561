<template>
	<div class="container-fluid animatedParent animateOnce">
		<!-- main content -->
		<div class="tab-content my-3" id="v-pills-tabContent">
			<div class="tab-pane animated fadeInUpShort show active go" id="v-pills-all" role="tabpanel" aria-labelledby="v-pills-all-tab">
				<div class="row my-3">
					<div class="col-md-12">
						<div class="card r-0 shadow">
							<div class="card-header">
								
								<div class="row">
									<div class="col-md-12">
										<!-- 搜索 start -->
										<div class="form-inline mt-3">
											<!-- <input v-model="query" class="form-control p-2" placeholder="搜索" type="text"> -->
											
											<!-- <div class="input-group">
												<input v-model="start_date" id="date_timepicker_start" type="text" class="date-time-picker form-control" style="width: 105px;">
												<span class="input-group-append">
													<span class="input-group-text add-on white">
														<i class="icon-calendar"></i>
													</span>
												</span>
											</div> -->
											<date-picker
												  v-model="start_date"
												  format="YYYY-MM-DD"
												  value-type="format" 
												  input-class="form-control" 
												  :editable="false" 
												  :clearable="false" 
												></date-picker>
											
											<!-- <div class="input-group ml-2">
												<input v-model="end_date" id="date_timepicker_end" type="text" class="date-time-picker form-control" style="width: 105px;" >
												<span class="input-group-append">
													<span class="input-group-text add-on white">
														<i class="icon-calendar"></i>
													</span>
												</span>
											</div> -->
											<div class="ml-2">
											<date-picker
												  v-model="end_date"
												  format="YYYY-MM-DD"
												  value-type="format" 
												  input-class="form-control" 
												  :editable="false" 
												  :clearable="false" 
												></date-picker>
											</div>
										
											<!-- 食堂特有 -->
											<span v-show="outlet_level==1">
												<select v-model="group_id" class="form-control  ml-2">
													<option value="0">所有级别</option>
													<option v-bind:key="index" :value="item.id"  v-for="(item,index) in activeCustomerGroups">{{item.name}}</option>
												</select>
												
												<select v-model="order_type" class="form-control ml-2">
													<option value="-1">所有订单类型</option>
													<option value="0">充值</option>
													<option value="1">退款</option>
												</select>
												
												<select v-model="pay_status" class="form-control ml-2"  v-show="false">
													<option value="-9">所有状态</option>
													<option value="0">待付款</option>
													<option value="1">已付款</option>
													<option value="-1">已取消</option>
												</select>
												
												<select v-model="app_way" class="form-control ml-2">
													<!-- 食堂使用 -->
													<option value="">所有来源</option>
													<option value="PC">后台</option>
													<option value="WEAPP">自助</option><!--  会员自助服务小程序充值 -->
												</select>
											</span>
											
											<button @click="search" type="button" class="btn btn-secondary  ml-2"><i class="icon-search"></i>
											</button>
										</div>
										<!-- 搜索 end -->
									</div>
									<!-- <div class="col-md-3">
										<div class="float-right m-3">
											 <button type="button" class="btn btn-secondary">导出</button>
										</div>
									</div>	 -->
								</div>	
							</div>
	
							<div class="table-responsive">
								<div v-if="rechargeorderlist.length > 0">
									<table class="table table-striped table-hover r-0  mb-0">
										<thead>
											<tr class="no-b">
												<th style="width: 60px">
													<!-- <div class="custom-control custom-checkbox">
														<input v-model="check_all" @click.stop="checkAll" type="checkbox"  class="custom-control-input" id="checkedAll"><label class="custom-control-label" for="checkedAll"></label>
													</div> -->
													序号
												</th>
												<th>时间</th>
												<th v-show="false">
													<div class="d-none d-lg-block">单号</div>
												</th>
												<th>
													<div class="d-none d-lg-block">卡号</div>
												</th>
												<th>
													<div class="d-none d-lg-block">级别</div>
												</th>
												<th>
													<div class="d-none d-lg-block">{{outlet_level==1?'现金':'实付'}}(元)</div>
												</th>
												<th>
													<div class="d-none d-lg-block">{{outlet_level==1?'补贴':'赠送'}}(元)</div>
												</th>
												<th>
													<div class="d-none d-lg-block">合计(元)</div>
												</th>
												<th>
													<div class="d-none d-lg-block">订单类型</div>
												</th>
												<th  v-show="false">
													<div class="d-none d-lg-block">状态</div>
												</th>
												<!-- <th>
													<div class="d-none d-lg-block">支付方式</div>
												</th> -->
												<th>
													<div class="d-none d-lg-block">来源</div>
												</th>
												<th>
													<div class="d-none d-lg-block">说明</div>
												</th>
												<!-- <th></th> -->
											</tr>
										</thead>
	
										<tbody>
											<tr v-bind:key="index" v-for="(item,index) in rechargeorderlist" :class="{'blue lighten-5':selectedIndex == index}">
												<td>
													{{index+1}}
													<!-- <label class="custom-control custom-checkbox">
														<input v-model="checkedIds" :value="item.id" :id="index" :disabled="item.enabled == -1" class="custom-control-input" type="checkbox"><label class="custom-control-label" :for="index"></label>
													</label> -->
												</td>
												<td>
													<div class="d-none d-lg-block">{{item.create_date}}</div>
												</td>
												
												<td v-show="false">
													<div class="d-none d-lg-block">{{item.order_no}}</div>
												</td>
												
												<td>
													<div class="d-none d-lg-block">{{item.customer_code}}</div>
												</td>
												
												<td>
													<div class="d-none d-lg-block">{{item.group_id}}</div>
												</td>
	
												<td>
													<div class="d-none d-lg-block"><span class="r-3  font-weight-bold">{{item.total_price}}</span></div>
												</td>
												<td>
													<div class="d-none d-lg-block"><span class="r-3  font-weight-bold">{{item.gift}}</span></div>
												</td>
												<td>
													<div class="d-none d-lg-block"><span class="r-3  font-weight-bold">{{item.total_amount}}</span></div>
												</td>
												<td>
													<div class="d-none d-lg-block">{{item.order_type==1?'退款':'充值'}}</div>
												</td>
												<td  v-show="false">
													<div class="d-none d-lg-block">
														{{item.pay_status}}
														<span :class="[ 'icon icon-circle s-12  mr-2',{'text-success' : item.pay_status == 1, 'text-danger':item.pay_status == 0, 'text-dark':item.pay_status == -1}]"></span>
													</div>
												</td>
												<!-- <td>
													<div class="d-none d-lg-block">{{item.payment}}</div>
												</td> -->
												<td>
													<div class="d-none d-lg-block">{{item.app_way=='PC'?'后台':'自助'}}</div>
												</td>
												<td>
													<div class="d-none d-lg-block">{{item.description}}</div>
												</td>
												<!-- <td>
													<button @click.stop="view(index)" type="button" class="btn btn-outline-primary btn-xs"> 详情 </button>
													
												</td> -->
											</tr>
	
	
										</tbody>
										<tfoot>
											<tr class="no-b">
												<th>
													
												</th>
												<th colspan="3">小计</th>
												<th>
													<div class="d-none d-lg-block">{{subtotalAmount}}</div>
												</th>
												<th colspan="3"></th>
											</tr>
											<tr class="no-b">
												<th>
													
												</th>
												<th colspan="3">合计</th>
												<th>
													<div class="d-none d-lg-block">{{totalAmount}}</div>
												</th>
												<th colspan="3"></th>
											</tr>
										</tfoot>
									</table>
								</div>
								<div v-if="rechargeorderlist.length == 0">
									<div class="card-body text-lg-center m-5 height-300">
									<h5>没有充值订单</h5>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
	
				<div class="row" v-if="rechargeorderlist.length > 0">
					<div class="col-sm-12 col-md-5">
						<div class="dataTables_info" id="example2_info" role="status" aria-live="polite">
							显示  {{show_from}} ~ {{show_to}} 条 / 共 {{count}} 条
						</div>
					</div>
					<div class="col-sm-12 col-md-7">
						
						<v-pagination :total="total" :current-page='current' @pagechange="pagechange"></v-pagination>
						
					</div>
				</div>
	
			</div>
	
		</div>	
		<!-- //main content -->
	</div>
</template>

<script>
	const __payment = {
	  'PC': '后台',
	  'WEAPP': '自助',
	}
	
	import VPagination from '../components/my-page.vue'
	import DatePicker from 'vue2-datepicker';
	import 'vue2-datepicker/index.css';
	import 'vue2-datepicker/locale/zh-cn';
	
	export default {
		data(){
			return{
				title: '充值订单列表',
				
				token: '',
				outlet_id: 0,
				outlet_name:'',
				user_name: '',
				outlet_level: 0,
				
				setting: {},
				outletlist: [],
				
				query: '',
				
				current_page: 1,
				page_size: 50,
				count: 0,
				total_page: 0,
				show_from: 0,
				show_to: 0,
				
				total: 0,     // 记录总条数
				display: 10,   // 每页显示条数
				current: 1,   // 当前的页数
				
				selectedIndex: -1,
				rechargeorder: {},//选中的对象
				
				//////
				start_date: '',
				end_date: '',
				recharge_type: -1,
				group_id: 0,
				order_type: -1,
				pay_status: 1,
				app_way: '',
				
				customergrouplist: [],
				rechargeorderlist: [],
				
				subtotalAmount:0,
				totalAmount:0,
			}
		},
		
		computed: {
			
			activeCustomerGroups: function () {
				let self = this;
				return this.customergrouplist.filter(function (item) {
					return item.outlet_id == self.outlet_id;//返回本店的
				})
			}
		},
		
		components: {
			VPagination,
			DatePicker
		},
		
		created: function() {
			console.log('--- created --- ')
			let self = this;
			
			//1.检查登录
			let token = localStorage.getItem('TOKEN');
			if (!token) {
				return;
			}
			self.token = token;
			
			//2.取得店铺信息
			let lsLoginData = JSON.parse(localStorage.getItem('LOGINDATA'));
			console.log(lsLoginData.logined_outlet_name)
			// self.outlet_id = lsLoginData.logined_outlet_id;
			console.info('--- this.$store.state ->',this.$store.state)
			self.outlet_id = this.$store.state.current_outlet_id || lsLoginData.logined_outlet_id;
			self.outlet_name = lsLoginData.logined_outlet_name;
			self.user_name = lsLoginData.user_account;
			self.outlet_level = lsLoginData.outlet_level;
			
			self.setting = JSON.parse(localStorage.getItem('SETTING'));
			
			self.outletlist = JSON.parse(localStorage.getItem('OUTLETLIST'));
			console.log('--- outlet list ->', self.outletlist)
		},
		
		mounted: function() {
			console.log('--- mounted --- ')
			let self = this;
			
			//1.检查登录
			// let token = localStorage.getItem('TOKEN');
			if (!self.token) {
				console.log('--- mounted jump  --- ')
				location.href = 'index.html';
				return;
			}
			
			self.init(() => {
				self.initData();
			});
		},
		methods: {
			
			init(callback) {
				let self = this;
			
				//取得员工列表
				self.axios.get(self.GLOBAL.baseURI + 'getAllCustomerGroupList', {
						params: {
							token: self.token,
							// outlet_id: self.outlet_id,
						}
					})
					.then(function(response) {
						console.log(response);
						return response.data;
					})
					.catch(function(error) {
						console.log(error);
					})
					.then(function(data) {
						// always executed
						console.log(data.data);
						if (data.code == 200) {
							self.customergrouplist = data.data;
							
							if (typeof callback == 'function') {
								callback()
							}
							
						} else if (data.code == 101) {
							//登录超时
							self.logoff();
						} else {
							alert(data.message)
						}
					});
				//END
			
			},
			
			initData(){
				let self = this;
				
				//取得业务数据
				self.axios.get(self.GLOBAL.baseURI + 'getRechargeOrderList', {
					params: {
						token: self.token,
						outlet_id: self.outlet_id,
						
						group_id: self.group_id,
						// recharge_type: self.recharge_type || -1,
						order_type: self.order_type || -1,
						app_way: self.app_way,
						pay_status: self.pay_status || -9,
						
						query: self.query,
						start_date: self.start_date,
						end_date: self.end_date,
						current_page: self.current_page,
						page_size: self.page_size,
					}
				})
				.then(function(response) {
					console.log(response);
					return response.data;
				})
				.catch(function(error) {
					console.log(error);
				})
				.then(function(data) {
					// always executed
					console.log(data.data);
					if (data.code == 200) { 
						
						self.rechargeorderlist = data.data.list;
						self.subtotalAmount = data.data.subtotalAmount;
						self.totalAmount = data.data.totalAmount;
						self.total = data.data.totalCount;
						
						self.start_date = data.data.start_date;
						self.end_date = data.data.end_date;
						
						self.current_page = data.data.page_num;
						self.page_size = data.data.page_size;
						self.count = data.data.total;
						self.total_page = Math.ceil(self.count/self.page_size);
						console.log('--- self.total_page ->',self.total_page)
						
						self.show_from = self.page_size*(self.current_page-1)+1;
						self.show_to = self.page_size*self.current_page<=self.count?self.page_size*self.current_page:self.count;
					
						self.total = self.count;     // 记录总条数
						self.display = self.page_size;   // 每页显示条数
						self.current = self.current_page;   // 当前的页数
					}
					else if (data.code == 101) { 
						//登录超时
						self.logoff();
					}
					else{
						alert(data.message)
					}
				});
				//END
				
			},
			
			pagechange:function(currentPage){
			   console.log(currentPage);
			   // ajax请求, 向后台发送 currentPage, 来获取对应的数据
			   this.current_page = currentPage;
			   this.initData();
			 },
			 
			search() {
				let self = this;
			
				self.initData();
			},
			
			view(index){
				console.log(index);
				let self = this;
				
				let rechargeorder = self.rechargeorderlist[index];
				console.log(index,rechargeorder);
				
				self.selectedIndex = index;
				self.rechargeorder = rechargeorder;
				
				// self.getSaleOrder(()=>{
				// 	$('#hModal').modal('show');
				// });
			},
			
			onOutletChangeListener(e) {
				let self = this;
				console.log('--- outlet ID->', e)
				self.outlet_id = e;
			
				self.init(() => {
					self.initData();
					self.group_id = 0;
				});
			},
			
		},
	}
	
</script>

<style>
	.mx-icon-calendar, .mx-icon-clear{
		right: 28px;
	}
</style>
